<template>
    <div id="page-halaman" class="container mb-5">
        <div class="container-box mt-4">
            <div class="container-header mt-4 mb-4">
                <div class="row align-items-center px-3">
                    <div class="col-md-6">
                        <h2>Tetapan Halaman</h2>
                    </div>
                </div>
            </div>
            <div class="contents">
                <ul class="nav nav-tabs">
                    <li class="nav-item">
                        <a href="#" class="nav-link" :class="{ 'active' : currentActiveNav == 1 }"
                            @click="currentActiveNav = 1">Siaran Langsung</a>
                    </li>
                    <li class="nav-item">
                        <a href="#" class="nav-link" :class="{ 'active' : currentActiveNav == 2 }"
                            @click="currentActiveNav = 2">Artikel</a>
                    </li>
                    <li class="nav-item">
                        <a href="#" class="nav-link" :class="{ 'active' : currentActiveNav == 3 }"
                            @click="currentActiveNav = 3">Banner</a>
                    </li>
                    <li class="nav-item">
                        <a href="#" class="nav-link" :class="{ 'active' : currentActiveNav == 4 }"
                            @click="currentActiveNav = 4">Peti Masuk</a>
                    </li>
                    <li class="nav-item">
                        <a href="#" class="nav-link" :class="{ 'active' : currentActiveNav == 5 }"
                            @click="currentActiveNav = 5">Galeri Gambar</a>
                    </li>
                    <!-- <li class="nav-item">
                        <a href="#" class="nav-link" :class="{ 'active' : currentActiveNav == 4 }"
                            @click="currentActiveNav = 4">Tarikh</a>
                    </li> -->
                </ul>

                <div class="nav-contents mt-4">
                    <div v-if="currentActiveNav == 1">
                        <div class="row mx-0">
                            <div class="col-md-6">
                                <h2>Senarai</h2>
                            </div>
                            <div class="col-md-6">
                                <div class="d-flex justify-content-end">
                                    <button class="btn btn-outline-secondary w-50 me-2" data-bs-toggle="modal"
                                        data-bs-target="#liveCourseModal" @click="addItemAction('liveCourse')">
                                        Tambah Baru +
                                    </button>
                                </div>
                            </div>
                        </div>
                        <hr class="dropdown-divider mt-2 mb-4" />
                        <div class="row mx-0">
                            <div v-for="liveCourse in liveCourses" :key="liveCourse.id" class="col-md-3 mb-2">
                                <div class="card" data-bs-toggle="modal" data-bs-target="#liveCourseModal"
                                    @click="editItemAction('liveCourse', liveCourse)">
                                    <div class="card-body">
                                        <div>
                                            <h5>{{ liveCourse.attributes.course_name }}</h5>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-else-if="currentActiveNav == 2">
                        <div class="row mx-0">
                            <div class="col-md-6">
                                <h2>Senarai</h2>
                            </div>
                            <div class="col-md-6">
                                <div class="d-flex justify-content-end">
                                    <button class="btn btn-outline-secondary w-50 me-2" data-bs-toggle="modal"
                                        data-bs-target="#articleModal" @click="addItemAction('article')">
                                        Tambah Baru +
                                    </button>
                                </div>
                            </div>
                        </div>
                        <hr class="dropdown-divider mt-2 mb-4" />
                        <div class="row mx-0">
                            <div v-for="article in articles" :key="article.id" class="col-md-3 mb-2">
                                <div class="card" data-bs-toggle="modal" data-bs-target="#articleModal"
                                    @click="editItemAction('article', article)">
                                    <div class="card-body">
                                        <div v-if="article.attributes.featured_img_file && article.attributes.featured_img_file.data"
                                            class="featured-img mb-2">
                                            <img :src="article.attributes.featured_img_file.data.attributes.formats && article.attributes.featured_img_file.data.attributes.formats.thumbnail.url"
                                                alt="">
                                        </div>
                                        <div>
                                            <h5>{{ article.attributes.title }}</h5>
                                            <p class="text-ecllips">{{ article.attributes.body }}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-else-if="currentActiveNav == 3">
                        <div class="row mx-0">
                            <div class="col-md-6">
                                <h2>Senarai</h2>
                            </div>
                            <div class="col-md-6">
                                <div class="d-flex justify-content-end">
                                    <button class="btn btn-outline-secondary w-50 me-2" data-bs-toggle="modal"
                                        data-bs-target="#bannerModal" @click="addItemAction('banner')">
                                        Tambah Baru +
                                    </button>
                                </div>
                            </div>
                        </div>
                        <hr class="dropdown-divider mt-2 mb-4" />
                        <div class="row mx-0">
                            <div v-for="banner in banners" :key="banner.id" class="col-12 mb-3">
                                <div class="card" data-bs-toggle="modal" data-bs-target="#bannerModal"
                                    @click="editItemAction('banner', banner)">
                                    <div class="card-body">
                                        <div class="featured-img">
                                            <img src="" alt="">
                                        </div>
                                        <div v-if="banner.img_file && banner.img_file.data && banner.img_file.data.length > 0 && banner.img_file.data[0]"
                                            class="featured-img mb-2">
                                            <img :src="banner.img_file.data[0].attributes.url" alt="">
                                        </div>
                                        <div>
                                            <h5>{{ banner.title }}</h5>
                                            <p>{{ banner.description }}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-else-if="currentActiveNav == 4">
						<div class="row mx-0">
							<div class="col-md-6">
								<h2>Senarai Notifikasi</h2>
							</div>
							<div class="col-md-6">
								<div class="d-flex justify-content-end">
									<button class="btn btn-outline-secondary w-50 me-2" data-bs-toggle="modal"
										data-bs-target="#inboxModal" 
										@click="addItemAction('banner')">
										Tambah Baru +
									</button>
								</div>
							</div>
						</div>
						<hr class="dropdown-divider" />
						<div class="row mx-0 gy-0">
							<div class="col-3 bg-white px-0 pt-0" style="background-clip: content-box">
								<h5 class="m-3"><i class="bi bi-inboxes me-2"></i>Peti Masuk</h5>
								<ul class="list-group mb-2" v-if="inboxs.length" id="inbox-list">
									<li
										class="
											list-group-item
											d-flex
											justify-content-between
											align-items-center
											custom-list
											me-1
										"
										v-for="(inbox, i) in inboxs"
										:key="'inbox' + i"
										:id="'inbox' + i"
										@click="selectedInboxNo = null;selectInbox(inbox, i)"
									>
										<div style="float: left; width: 80%">
											<p class="mb-1">{{ inbox.subject }}</p>
											<span class="inbox-date">{{ getDatetime(inbox.createdAt) }}</span>
										</div>
										<div class="float-end me-2">
											<span>
												<i class="bi bi-arrow-right"></i>
											</span>
										</div>
									</li>
								</ul>
							</div>
							<div id="v-editor" class="col-9 pt-2 px-4" v-if="selectedInboxNo !== null">
                                <div class="float-end mb-2">
                                    <button class="btn btn-outline-secondary"
										@click="submitDelete('inbox')">
										Buang Mesej&nbsp;<i class="bi bi-trash"></i>
									</button>
                                </div>
                                <v-md-editor
									v-model="inboxs[selectedInboxNo].content"
									mode="preview"
									height="600px"
								></v-md-editor>
							</div>
						</div>
					</div>
                    <div v-else-if="currentActiveNav == 5">
                        <div class="row mx-0">
                            <div class="col-md-6">
                                <h2>Senarai</h2>
                            </div>
                            <div class="col-md-6">
                                <div class="d-flex justify-content-end">
                                    <button class="btn btn-outline-secondary w-50 me-2" data-bs-toggle="modal"
                                        data-bs-target="#imageGalleryModal" @click="addItemAction('imageGallery')">
                                        Tambah Baru +
                                    </button>
                                </div>
                            </div>
                        </div>
                        <hr class="dropdown-divider mt-2 mb-4" />
                        <div class="row mx-0">
                            <div v-for="imgGallery in imageGalleries" :key="imgGallery.id" class="col-6 col-md-4 mb-3">
                                <div class="card" data-bs-toggle="modal" data-bs-target="#imageGalleryModal"
                                    @click="editItemAction('imageGallery', imgGallery)">
                                    <div class="card-body">
                                        <div v-if="imgGallery.attributes.file && imgGallery.attributes.file.data"
                                            class="featured-img mb-2" style="height: 250px;">
                                            <img :src="imgGallery.attributes.file.data.attributes.url" alt="" style="height: 100%; width: 100%; object-fit: cover;">
                                        </div>
                                        <div>
                                            <h5>{{ imgGallery.title }}</h5>
                                            <p>{{ imgGallery.description }}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- Modal -->
        <div class="modal" id="liveCourseModal" tabindex="-1" aria-labelledby="liveCourseModalLabel" aria-hidden="true" data-bs-backdrop="static" data-bs-keyboard="false">
            <div class="modal-dialog modal-lg modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-body">
											<div class="row mx-0">
												<div class="col-12 pe-4">
													<h5 v-if="modalModeAction == 'add'">Tambah Baru</h5>
													<h5 v-else-if="modalModeAction == 'edit'">Sunting</h5>

													<div class="form-group mt-3">
														<label for="tajuk" class="control-label mb-1">Tajuk</label>
														<input
															type="text"
															class="form-control"
															placeholder="Letak tajuk"
															v-model="liveCourseObj.course_name"
														/>
													</div>
													<div class="form-group mt-3">
														<label for="tajuk" class="control-label mb-1"
															>Description</label
														>
														<textarea
															class="form-control"
															rows="3"
															placeholder="Letak description"
															v-model="liveCourseObj.description"
														></textarea>
													</div>
													<div class="form-group mt-3">
														<label for="tajuk" class="control-label mb-1"
															>Kod Embed HTML</label
														>
														<!-- <v-md-editor
															v-model="liveCourseObj.embed_html"
															mode="editable"
															height="600px"
														></v-md-editor> -->
                                                        <text-editor
                                                            v-if="modalAppear"
                                                            :html_content="liveCourseObj.embed_html"
                                                            @set-content="(_val) => { setHTMLContent(_val, 'liveCourseObj') }"
                                                        />
													</div>
                                                    <div class="form-group mt-3">
                                                        <label for="name" class="control-label mb-1">Akses
                                                            Kepada</label>
                                                        <!-- <div v-for="(role, i) in role_access" 
                                                                :key="role.name + i"
                                                                class="form-check">
                                                            <input class="form-check-input" type="radio" v-model="liveCourseObj.role_access" :value="role.value">
                                                            <label class="form-check-label">
                                                            {{ role.name }}
                                                            </label>
                                                        </div> -->
                                                        <div v-for="access_type in user_access_types"
                                                            :key="access_type.id" class="form-check">
                                                            <input class="form-check-input" type="checkbox"
                                                                v-model="liveCourseObj.user_access_types"
                                                                :value="access_type.id">
                                                            <label class="form-check-label">
                                                                {{ access_type.attributes.label }}
                                                            </label>
                                                        </div>
                                                    </div>
													<div class="form-group mt-3">
														<label for="name" class="control-label mb-1"
															>Tarikh Mula</label
														>
														<input
															type="datetime-local"
															v-model="liveCourseObj.start_datetime"
															class="form-control"
															placeholder="Kursus Perdana Haji (KPH)"
														/>
													</div>
													<div class="form-group mt-3">
														<label for="name" class="control-label mb-1"
															>Tarikh Berakhir</label
														>
														<input
															type="datetime-local"
															v-model="liveCourseObj.end_datetime"
															class="form-control"
															placeholder="Kursus Perdana Haji (KPH)"
														/>
													</div>
													<div class="form-group mt-3">
														<label for="tajuk" class="control-label mb-1">Zoom ID</label>
														<input
															type="text"
															class="form-control"
															placeholder="Letak zoom id"
															v-model="liveCourseObj.zoom_id"
														/>
													</div>
													<div class="form-group mt-3">
														<label for="tajuk" class="control-label mb-1"
															>Zoom Password</label
														>
														<input
															type="text"
															class="form-control"
															placeholder="Letak zoom password"
															v-model="liveCourseObj.zoom_password"
														/>
													</div>
													<div class="form-group mt-3 custom-checkbox">
														<label for="name" class="control-label mb-1"
															>Status Kursus</label
														><br />
														<input
															class="form-check-input"
															type="checkbox"
															v-model="liveCourseObj.is_active"
															id="kuiz"
														/>
														<label class="form-check-label ms-3" for="kuiz">
															{{ liveCourseObj.is_active ? "Aktif" : "Tidak aktif" }}
														</label>
													</div>
												</div>
											</div>
										</div>
                    <div class="modal-footer mt-4">
                        <button type="button" class="btn btn-outline-secondary" data-bs-dismiss="modal" @click="modalAppear = false">
                            Batal
                        </button>
                        <button v-if="modalModeAction == 'add'" type="button" class="btn btn-primary" @click="submitNew('liveCourse')">
                            Simpan
                        </button>
                        <button v-if="modalModeAction == 'edit'" type="button" class="btn btn-danger" @click="submitDelete('liveCourse', liveCourseObj)">
                            Padam
                        </button>
                        <button v-if="modalModeAction == 'edit'" type="button" class="btn btn-primary" @click="submitUpdate('liveCourse', liveCourseObj)">
                            Kemaskini
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal" id="articleModal" tabindex="-1" aria-labelledby="articleModalLabel" aria-hidden="true" data-bs-backdrop="static" data-bs-keyboard="false">
            <div class="modal-dialog modal-lg modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-body">
                        <div class="row mx-0">
                            <div class="col-12 pe-4">
                                <h5 v-if="modalModeAction == 'add'">Tambah Artikel Baru</h5>
                                <h5 v-else-if="modalModeAction == 'edit'">Sunting Artikel</h5>

                                 <div class="form-group mt-3">
                                     <label for="tajuk" class="control-label mb-1">Tajuk</label>
                                     <input type="text" class="form-control"
                                         placeholder="Letak tajuk" v-model="articleObj.title" />
                                 </div>
                                 <div class="form-group mt-3">
                                     <label for="tajuk" class="control-label mb-1">Description</label>
                                     <!-- <v-md-editor
                                        v-model="articleObj.body"
                                        mode="editable"
                                        height="600px"
                                    ></v-md-editor> -->
                                    <text-editor
                                        v-if="modalAppear"
                                        :html_content="articleObj.html_content"
                                        @set-content="(_val) => { setHTMLContent(_val, 'articleObj') }"
                                    />
                                     <!-- <textarea class="form-control" rows="20" placeholder="Letak description" v-model="articleObj.body"></textarea> -->
                                 </div>
                                 <div v-if="articleObj.featured_img_file && articleObj.featured_img_file.data" class="form-group mt-3">
                                    <div class="row">
                                        <div class="col-md-4">
                                            <div class="card" @click="removeImage('article')">
                                                <div class="card-body">
                                                    <img width="100%" :src="articleObj.featured_img_file.data.attributes.formats.thumbnail.url" alt="">
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                 </div>
                                 <div v-else>
                                    <label for="tajuk" class="control-label mb-1">Muat Naik Imej</label>
                                    <input
                                        accept="image/png,image/jpeg"
                                        type="file"
                                        class="form-control"
                                        placeholder="Letak imej"
                                        id="articleImgFile"
                                        lang="es"
                                        ref="articleImgFile"
                                        @change="fileUpload('articleImgFile')"
                                    />
                                    <small v-if="isFileError" class="control-label mb-1" style="color: red;font-style: italic;">Ralat. Fail ini tidak akan dimuatnaik.</small>
                                 </div>
                                 <div class="form-check form-switch mt-3">
                                     <input class="form-check-input" type="checkbox" id="isActiveBox" v-model="articleObj.is_active">
                                     <label class="form-check-label" for="isActiveBox">
                                         Aktif
                                     </label>
                                 </div>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer mt-4">
                        <button type="button" class="btn btn-outline-secondary" data-bs-dismiss="modal" @click="modalAppear = false">
                            Batal
                        </button>
                        <button v-if="modalModeAction == 'add'" type="button" class="btn btn-primary" @click="submitNew('article')">
                            Simpan
                        </button>
                        <button v-if="modalModeAction == 'edit'" type="button" class="btn btn-danger" @click="submitDelete('article', articleObj)">
                            Padam
                        </button>
                        <button v-if="modalModeAction == 'edit'" type="button" class="btn btn-primary" @click="submitUpdate('article', articleObj)">
                            Kemaskini
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal" id="bannerModal" tabindex="-1" aria-labelledby="bannerModalLabel" aria-hidden="true" data-bs-backdrop="static" data-bs-keyboard="false">
            <div class="modal-dialog modal-lg modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-body">
                        <div class="row mx-0">
                            <div class="col-12 pe-4">
                                <h5 v-if="modalModeAction == 'add'">Tambah Banner Baru</h5>
                                <h5 v-else-if="modalModeAction == 'edit'">Sunting Banner</h5>

                                 <div class="form-group mt-3">
                                     <label for="tajuk" class="control-label mb-1">Tajuk</label>
                                     <input type="text" class="form-control"
                                         placeholder="Letak tajuk" v-model="bannerObj.title" />
                                 </div>
                                 <div class="form-group mt-3">
                                     <label for="tajuk" class="control-label mb-1">Description</label>
                                     <textarea class="form-control" rows="3" placeholder="Letak description" v-model="bannerObj.description"></textarea>
                                 </div>
                                 <div class="form-group mt-3">
                                     <label for="tajuk" class="control-label mb-1">URL</label>
                                     <textarea class="form-control" rows="3" placeholder="Letak url" v-model="bannerObj.url"></textarea>
                                 </div>
                                 <div v-if="bannerObj.img_file && bannerObj.img_file.data && bannerObj.img_file.data.length > 0 && bannerObj.img_file.data[0]" class="form-group mt-3">
                                    <div class="row">
                                        <div class="col-12">
                                            <div class="card" @click="removeImage('banner')">
                                                <div class="card-body">
                                                    <img width="100%" :src="bannerObj.img_file.data[0].attributes.url" alt="">
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                 </div>
                                 <div v-else>
                                    <label for="tajuk" class="control-label mb-1">Muat Naik Imej</label>
                                    <input
                                        accept="image/png,image/jpeg"
                                        type="file"
                                        class="form-control"
                                        placeholder="Letak imej"
                                        id="bannerImgFile"
                                        lang="es"
                                        ref="bannerImgFile"
                                        @change="fileUpload('bannerImgFile')"
                                    />
                                    <small v-if="isFileError" class="control-label mb-1" style="color: red;font-style: italic;">Ralat. Fail ini tidak akan dimuatnaik.</small>
                                 </div>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer mt-4">
                        <button type="button" class="btn btn-outline-secondary" data-bs-dismiss="modal" @click="modalAppear = false">
                            Batal
                        </button>
                        <button v-if="modalModeAction == 'add'" type="button" class="btn btn-primary" @click="submitNew('banner')">
                            Simpan
                        </button>
                        <button v-if="modalModeAction == 'edit'" type="button" class="btn btn-danger" @click="submitDelete('banner', bannerObj)">
                            Padam
                        </button>
                        <button v-if="modalModeAction == 'edit'" type="button" class="btn btn-primary" @click="submitUpdate('banner', bannerObj)">
                            Kemaskini
                        </button>
                    </div>
                </div>
            </div>
        </div>
		<div class="modal" id="inboxModal" tabindex="-1" aria-labelledby="inboxModalLabel" aria-hidden="true" data-bs-backdrop="static" data-bs-keyboard="false">
            <div class="modal-dialog modal-lg modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-body">
                        <div class="row mx-0">
                            <div class="col-12">
                                <h5 v-if="modalModeAction == 'add'">Tambah Pengumuman</h5>

                                 <div class="form-group mt-3">
                                     <label for="tajuk" class="control-label mb-1">Tajuk</label>
                                     <input type="text" class="form-control"
                                         placeholder="Letak tajuk" v-model="inboxObj.subject" />
                                 </div>
                                 <div class="form-group mt-3">
                                    <label for="name" class="control-label mb-1">Hantar Kepada</label>
									<div class="form-check" v-for="(target, i) in targetRoles" :key="i" >
										<input class="form-check-input" type="checkbox" :id="'radio' + i" :value="target.value" v-model="inboxObj.target_roles">
										<label class="form-check-label">
											{{ target.name }}
										</label>
									</div>
                                 </div>
                                 <div class="form-group mt-3">
                                    <label for="name" class="control-label mb-1">Negeri</label>
                                    <select class="form-select" id="tag-select" v-model="inboxObj.state">
                                        <option
                                            v-for="state in stateList"
                                            :key="state"
                                            :value="state"
                                            >
                                            {{ state }}
                                        </option>
                                    </select>
                                 </div>
                                 <div class="form-group mt-3">
                                    <!-- <v-md-editor
										v-model="inboxObj.content"
										height="600px"
									></v-md-editor> -->
                                    <text-editor
                                        v-if="modalAppear"
                                        :html_content="inboxObj.html_content"
                                        @set-content="(_val) => { setHTMLContent(_val, 'inboxObj') }"
                                    />
                                 </div>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer mt-4">
                        <button type="button" class="btn btn-outline-secondary" data-bs-dismiss="modal" @click="modalAppear = false">
                            Batal
                        </button>
                        <button type="button" class="btn btn-primary" @click="submitNew('inbox')">
                            Hantar
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal" id="imageGalleryModal" tabindex="-1" aria-labelledby="imageGalleryModalLabel" aria-hidden="true" data-bs-backdrop="static" data-bs-keyboard="false">
            <div class="modal-dialog modal-lg modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-body">
                        <div class="row mx-0">
                            <div class="col-12 pe-4">
                                <h5 v-if="modalModeAction == 'add'">Tambah Gambar Baru</h5>
                                <h5 v-else-if="modalModeAction == 'edit'">Sunting Gambar</h5>

                                 <div v-if="imageGalleryObj && imageGalleryObj.file && imageGalleryObj.file.data" class="form-group mt-3">
                                    <div class="row">
                                        <div class="col-12">
                                            <div class="card" @click="removeImage('imageGallery')">
                                                <div class="card-body">
                                                    <img width="100%" :src="imageGalleryObj.file.data.attributes.url" alt="">
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                 </div>
                                 <div v-else>
                                    <label for="tajuk" class="control-label mb-1">Muat Naik Gambar</label>
                                    <input
                                        accept="image/png,image/jpeg"
                                        type="file"
                                        class="form-control"
                                        placeholder="Letak imej"
                                        id="imageGalleryFile"
                                        lang="es"
                                        ref="imageGalleryFile"
                                        @change="fileUpload('imageGalleryFile')"
                                    />
                                    <small v-if="isFileError" class="control-label mb-1" style="color: red;font-style: italic;">Ralat. Fail ini tidak akan dimuatnaik.</small>
                                 </div>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer mt-4">
                        <button type="button" class="btn btn-outline-secondary" data-bs-dismiss="modal" @click="modalAppear = false">
                            Batal
                        </button>
                        <button v-if="modalModeAction == 'add'" type="button" class="btn btn-primary" @click="submitNew('imageGallery')">
                            Simpan
                        </button>
                        <button v-if="modalModeAction == 'edit'" type="button" class="btn btn-danger" @click="submitDelete('imageGallery', imageGalleryObj)">
                            Padam
                        </button>
                        <button v-if="modalModeAction == 'edit'" type="button" class="btn btn-primary" @click="submitUpdate('imageGallery', imageGalleryObj)">
                            Kemaskini
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import API from "../../utils/API";
import TextEditor from './../../components/TextEditor.vue'
import moment from "moment";
import $ from "jquery";
import Helper from '../../utils/helper';

export default {
    data(){
        return {
            currentActiveNav: 2, //1 - Siaran Langsung, 2- Artikel, 3 - Banner, 4- Pengumuman, 5- Galeri Gambar
            modalModeAction: null, //add & edit,
            modalAppear: false,
            liveCourses: [],
            articles: [],
            banners: [],
            inboxs: [],
            imageGalleries: [],
            selectedInboxNo: null,
            selectedInbox: null,
            user_access_types: [],
            liveCourseObj: {
                id: null,
                course_name: null,
                description: null,
                course_type: 'live',
                is_active: null,
                embed_html: "",
                start_datetime: null,
                end_datetime: null,
                zoom_id: null,
                zoom_password: null,
                role_access: null,
                user_access_types: []
            },
            articleObj: {
                id: null,
                title: null,
                body: "",
                featured_img_file: null,
                is_active: null,
                html_content: null
            },
            bannerObj: {
                id: null,
                title: null,
                description: null,
                img_file: null,
                url: null
            },
			targetRoles: [
				{ value: "bh", name: "BH - Bakal Haji"},
				{ value: "mp", name: "Umum"},
				{ value: "pekta", name: "PEKTA"},
				{ value: "petugas", name: "Petugas Haji"}
			],
			inboxObj: {
				subject: null,
				content: "Masukkan kandungan disini",
				target_roles: [],
                state: null,
                html_content: null
			},
            imageGalleryObj: {
                id: null,
                title: null,
                description: null,
                file: null,
                type: 'image-gallery',
                // gallery_file_types: [ 6 ]
            },
            role_access: [
                { name: "BH - Bakal Haji", value: "bh" },
                { name: "MP - Umum", value: "mp" },
                { name: "PEK - PEKTA", value: "pekta" },
                { name: "Petugas Haji", value: "petugas" },
                { name: "Umum", value: "public" },
            ],
            stateList: [
                "Johor",
                "Kedah",
                "Kelantan",
                "Melaka",
                "Negeri Sembilan",
                "Pahang",
                "Perak",
                "Perlis",
                "Pulau Pinang",
                "Sabah",
                "Sarawak",
                "Selangor",
                "Terengganu",
                "Kuala Lumpur",
            ],
            imgFileToUpload: null,
            isFileError: false
        }
    },
    components: {
        'text-editor': TextEditor
    },
    methods: {
        async fetchData(){
            const res = await API.get('articles?populate=%2A')
            this.articles = res.data.data

            const res02 = await API.get('homepage?populate[banner][populate][0]=img_file')
            this.banners = res02.data.data.attributes.banner

            const res03 = await API.get('courses?populate=%2A&filters[course_type][$eq]=live')
            this.liveCourses = res03.data.data

			const res04 = await API.get('inboxes?sort[0]=createdAt%3Adesc&filters[is_deleted][$eq]=false')
            this.inboxs = res04.data.data.map((o) => {
                return Object.assign({}, { id: o.id }, o.attributes);
            });

            const res05 = await API.get('gallery-files?populate=%2A&sort[0]=updatedAt%3Adesc&pagination[limit]=100&filters[type][$eq]=image-gallery')
            this.imageGalleries = res05.data.data

            const res06 = await API.get(`user-access-types`)
            this.user_access_types = res06.data.data
        },
        async submitNew(_arg){
            console.log('start new submission')
            switch (_arg) {
                case 'liveCourse':
                    if (this.liveCourses.start_datetime)
                        this.liveCourses.start_datetime = new Date(this.liveCourses.start_datetime).toISOString()
                    if (this.liveCourses.end_datetime)
                        this.liveCourses.end_datetime = new Date(this.liveCourses.end_datetime).toISOString()
                    let res = await API.post(`courses?populate=%2A`, {
                        data: this.liveCourseObj,
                    })
                    this.liveCourses.push(res.data.data)

                    this.liveCourseObj = {
                        id: null,
                        course_name: null,
                        description: null,
                        course_type: 'live',
                        is_active: null,
                        embed_html: "",
                        start_datetime: null,
                        end_datetime: null,
                        zoom_id: null,
                        zoom_password: null,
                        user_access_types: []
                    }

                    break;
                case 'article':
                    if(this.imgFileToUpload) 
                        this.articleObj.featured_img_file = await this.uploadFile()

                    let res02 = await API.post(`articles?populate=%2A`, {
                        data: this.articleObj,
                    })
                    this.articles.push(res02.data.data)

                    this.articleObj = {
                        id: null,
                        title: null,
                        body: null,
                        featured_img_file: null,
                        is_active: null,
                        html_content: null
                    }

                    break;
                case 'banner':
                    if(this.imgFileToUpload) 
                        this.bannerObj.img_file = await this.uploadFile()

                    //validate
                    this.bannerObj.title = Helper.validateAndRemoveUnwantedTags(this.bannerObj.title)
                    this.bannerObj.description = Helper.validateAndRemoveUnwantedTags(this.bannerObj.description)
                    this.bannerObj.url = Helper.validateAndRemoveUnwantedTags(this.bannerObj.url)
                    
                    let banners = []
                    this.banners.forEach(item => banners.push({ id: item.id }))
                    banners.push(this.bannerObj)
                    let res03 = await API.put(`homepage?populate[banner][populate][0]=img_file`, {
                        data: {
                            banner: banners
                        },
                    })
                    this.banners = res03.data.data.attributes.banner

                    this.bannerObj = {
                        id: null,
                        title: null,
                        description: null,
                        img_file: null,
                        url: null
                    }
                    break;
				case 'inbox':
                    this.inboxObj.target_roles = this.inboxObj.target_roles.join(',');
					let res04 = await API.post(`inboxes?populate=%2A`, {
                        data: this.inboxObj,
                    })
                    this.inboxs.unshift(Object.assign(res04.data.data.attributes, {id: res04.data.data.id}))

                    this.inboxObj = {
                        subject: null,
                        content: "Masukkan kandungan disini",
                        target_roles: [],
                        state: null,
                        html_content: null
                    }
                    break;
                case 'imageGallery':
                    if(this.imgFileToUpload) 
                        this.imageGalleryObj.file = await this.uploadFile()

                    let res05 = await API.post(`gallery-files?populate=%2A`, {
                        data: this.imageGalleryObj,
                    })
                    this.imageGalleries.unshift(res05.data.data)

                    this.imageGalleryObj = {
                        id: null,
                        title: null,
                        description: null,
                        file: null,
                        type: 'image-gallery',
                        // gallery_file_types: [ 6 ]
                    }
                    break;
            }
            this.imgFileToUpload = null
            $(`#${_arg}Modal`).toggle()
            $('.modal-backdrop').remove()
            this.modalAppear = false
        },
        async submitUpdate(_arg, _objItem){
            console.log('start update submission')
            switch (_arg) {
                case 'liveCourse':
                    if (this.liveCourses.start_datetime)
                        this.liveCourses.start_datetime = new Date(this.liveCourses.start_datetime).toISOString()
                    if (this.liveCourses.end_datetime)
                        this.liveCourses.end_datetime = new Date(this.liveCourses.end_datetime).toISOString()
                    let res = await API.put(`courses/${_objItem.id}?populate=%2A`, {
                        data: this.liveCourseObj,
                    })
                    
                    let foundInd = this.liveCourses.findIndex(item => item.id == this.liveCourseObj.id)
                    this.liveCourses[foundInd] = res.data.data

                    this.liveCourseObj = {
                        id: null,
                        course_name: null,
                        description: null,
                        course_type: 'live',
                        is_active: null,
                        embed_html: null,
                        start_datetime: null,
                        end_datetime: null,
                        zoom_id: null,
                        zoom_password: null,
                        user_access_types: []
                    }

                    break;
                case 'article':
                    if(this.imgFileToUpload) 
                        this.articleObj.featured_img_file = await this.uploadFile()
                    else delete this.articleObj.featured_img_file

                    let res02 = await API.put(`articles/${_objItem.id}?populate=%2A`, {
                        data: this.articleObj,
                    })
                    
                    let foundInd02 = this.articles.findIndex(item => item.id == this.articleObj.id)
                    this.articles[foundInd02] = res02.data.data

                    this.articleObj = {
                        id: null,
                        title: null,
                        body: null,
                        featured_img_file: null,
                        is_active: null,
                        html_content: null
                    }

                    break;
                case 'banner':
                    if(this.imgFileToUpload) 
                        this.bannerObj.img_file = await this.uploadFile()
                    else delete this.bannerObj.img_file

                    //validate
                    this.bannerObj.title = Helper.validateAndRemoveUnwantedTags(this.bannerObj.title)
                    this.bannerObj.description = Helper.validateAndRemoveUnwantedTags(this.bannerObj.description)
                    this.bannerObj.url = Helper.validateAndRemoveUnwantedTags(this.bannerObj.url)

                    let banners = []
                    this.banners.forEach(item => {
                        if(this.bannerObj.id == item.id)
                            banners.push(this.bannerObj)
                        else
                            banners.push({ id: item.id })
                    })
                    
                    let res03 = await API.put(`homepage?populate[banner][populate][0]=img_file`, {
                        data: {
                            banner: banners
                        },
                    })
                    this.banners = res03.data.data.attributes.banner

                    this.bannerObj = {
                        id: null,
                        title: null,
                        description: null,
                        img_file: null,
                        url: null,
                        html_content: null
                    }
                    break;
                case 'imageGallery':
                    if(this.imgFileToUpload) 
                        this.imageGalleryObj.file = await this.uploadFile()
                    else delete this.imageGalleryObj.file

                    let res05 = await API.put(`gallery-files/${_objItem.id}?populate=%2A`, {
                        data: this.imageGalleryObj,
                    })
                    let foundInd05 = this.imageGalleries.findIndex(item => item.id == this.imageGalleryObj.id)
                    this.imageGalleries[foundInd05] = res05.data.data

                    this.imageGalleryObj = {
                        id: null,
                        title: null,
                        description: null,
                        file: null,
                        type: 'image-gallery',
                        // gallery_file_types: [ 6 ]
                    }
                    break;
            }
            this.imgFileToUpload = null
            $(`#${_arg}Modal`).toggle()
            $('.modal-backdrop').remove()
            this.modalAppear = false
        },
        async submitDelete(_arg, _objItem){
            console.log('start delete submission')
            switch (_arg) {
                case 'liveCourse':
                    const res1 = await API.delete(`courses/${_objItem.id}`)
                    
                    if (res1.toDelete) {
                        let foundInd = this.liveCourses.findIndex(item => item.id == this.liveCourseObj.id)
                        this.liveCourses.splice(foundInd, 1)
                    }

                    this.liveCourseObj = {
                        id: null,
                        course_name: null,
                        description: null,
                        course_type: 'live',
                        is_active: null,
                        embed_html: null,
                        start_datetime: null,
                        end_datetime: null,
                        zoom_id: null,
                        zoom_password: null,
                        user_access_types: []
                    }

                    break;
                case 'article':
                    const res2 = await API.delete(`articles/${_objItem.id}`)
                    
                    if (res2.toDelete) {
                        let foundInd02 = this.articles.findIndex(item => item.id == this.articleObj.id)
                        this.articles.splice(foundInd02, 1)
                    }

                    this.articleObj = {
                        id: null,
                        title: null,
                        body: null,
                        featured_img_file: null,
                        is_active: null,
                        html_content: null
                    }

                    break;
                case 'banner':
                    let banners = []
                    this.banners.forEach(item => banners.push({ id: item.id }))

                    let foundInd03 = banners.findIndex(item => item.id == this.bannerObj.id)
                    banners.splice(foundInd03, 1)

                    let res02 = await API.put(`homepage?populate[banner][populate][0]=img_file`, {
                        data: {
                            banner: banners
                        },
                    })
                    this.banners = res02.data.data.attributes.banner

                    this.bannerObj = {
                        id: null,
                        title: null,
                        description: null,
                        img_file: null,
                        url: null
                    }

                    break;
				
                case 'inbox':
                    await API.put(`inboxes/${this.selectedInbox.id}`, {data: { is_deleted: true}})
                    this.selectedInboxNo = null;
                    let foundInd04 = this.inboxs.findIndex(item => item.id == this.selectedInbox.id)
                    this.inboxs.splice(foundInd04, 1)
                    break;

                case 'imageGallery':
                    const res5 = await API.delete(`gallery-files/${_objItem.id}`)
                    
                    if (res5.toDelete) {
                        let foundInd05 = this.imageGalleries.findIndex(item => item.id == this.imageGalleryObj.id)
                        this.imageGalleries.splice(foundInd05, 1)
                    }

                    this.imageGalleryObj = {
                        id: null,
                        title: null,
                        description: null,
                        file: null,
                        type: 'image-gallery',
                        // gallery_file_types: [ 6 ]
                    }
                    break;
            }
            this.imgFileToUpload = null
			if (_arg !== 'inbox') {
				$(`#${_arg}Modal`).toggle()
				$('.modal-backdrop').remove()
			}
            
            this.modalAppear = false
				
        },
        async addItemAction(_itemName){
            this.modalAppear = true
            this.modalModeAction = 'add'
            this.imgFileToUpload = null

            switch (_itemName) {
                case 'liveCourse':
                    delete this.liveCourseObj.id
                    this.liveCourseObj.course_name = null
                    this.liveCourseObj.description = null
                    this.liveCourseObj.course_type = 'live'
                    this.liveCourseObj.is_active = null
                    this.liveCourseObj.embed_html = ""
                    this.liveCourseObj.start_datetime = null
                    this.liveCourseObj.end_datetime = null
                    this.liveCourseObj.zoom_id = null
                    this.liveCourseObj.zoom_password = null
                    this.liveCourseObj.user_access_types = []
                    break;
                case 'article':
                    delete this.articleObj.id
                    this.articleObj.title = null
                    this.articleObj.body = ""
                    this.articleObj.featured_img_file = null
                    this.articleObj.is_active = null
                    this.articleObj.html_content = null
                    break;
                case 'banner':
                    delete this.bannerObj.id
                    this.bannerObj.title = null
                    this.bannerObj.description = null
                    this.bannerObj.img_file = null
                    this.bannerObj.url = null
                    break;
				case 'inbox':
					this.inboxObj.subject = null
					this.inboxObj.content = "Masukkan kandungan disini"
					this.inboxObj.target_roles = []
                    this.inboxObj.state = null
                    this.inboxObj.html_content = null
					break;
                case 'imageGallery':
                    delete this.imageGalleryObj.id
                    this.imageGalleryObj.title = null
                    this.imageGalleryObj.description = null
                    this.imageGalleryObj.file = null
                    this.imageGalleries.type = 'image-gallery'
                    // this.imageGalleryObj.gallery_file_types = [ 6 ]
                    break;
            }
        },
        async editItemAction(_itemName, _itemObj){
            this.modalAppear = true
            this.modalModeAction = 'edit'
            this.imgFileToUpload = null

            switch (_itemName) {
                case 'liveCourse':
                    this.liveCourseObj.id = _itemObj.id
                    this.liveCourseObj.course_name = _itemObj.attributes.course_name,
                    this.liveCourseObj.description = _itemObj.attributes.description,
                    this.liveCourseObj.course_type = _itemObj.attributes.course_type,
                    this.liveCourseObj.is_active = _itemObj.attributes.is_active,
                    this.liveCourseObj.embed_html = _itemObj.attributes.embed_html,
                    this.liveCourseObj.start_datetime = _itemObj.attributes.start_datetime ? new Date(_itemObj.attributes.start_datetime).toISOString().slice(0, 16) : null,
                    this.liveCourseObj.end_datetime = _itemObj.attributes.end_datetime ? new Date(_itemObj.attributes.end_datetime).toISOString().slice(0, 16) : null,
                    this.liveCourseObj.zoom_id = _itemObj.attributes.zoom_id,
                    this.liveCourseObj.zoom_password = _itemObj.attributes.zoom_password
                    this.liveCourseObj.role_access = _itemObj.attributes.role_access
                    this.liveCourseObj.user_access_types = _itemObj.attributes.user_access_types.data.length > 0 ? _itemObj.attributes.user_access_types.data.map(item => {
                        return item.id
                    }) : []
                    break;
                case 'article':
                    this.articleObj.id = _itemObj.id
                    this.articleObj.title = _itemObj.attributes.title
                    this.articleObj.body = _itemObj.attributes.body
                    this.articleObj.featured_img_file = _itemObj.attributes.featured_img_file
                    this.articleObj.is_active = _itemObj.attributes.is_active
                    this.articleObj.html_content = _itemObj.attributes.html_content
                    break;
                case 'banner':
                    this.bannerObj.id = _itemObj.id
                    this.bannerObj.title = _itemObj.title
                    this.bannerObj.description = _itemObj.description
                    this.bannerObj.img_file = _itemObj.img_file
                    this.bannerObj.url = _itemObj.url
                    break;
                case 'imageGallery':
                    this.imageGalleryObj.id = _itemObj.id
                    this.imageGalleryObj.title = _itemObj.attributes.title
                    this.imageGalleryObj.description = _itemObj.attributes.description
                    this.imageGalleryObj.file = _itemObj.attributes.file
                    this.imageGalleries.type = 'image-gallery'
                    // this.imageGalleryObj.gallery_file_types = [ 6 ]
                    break;
            }
        },
        async removeImage(_itemName){
            switch (_itemName) {
                case 'article':
                    this.articleObj.featured_img_file = null
                    break;
                case 'banner':
                    this.bannerObj.img_file = null
                    break;
                case 'imageGallery':
                    this.imageGalleryObj.file = null
                    break;
            }
        },
        fileUpload(_ref) {
            this.isFileError = false //default is false

            if (this.$refs[_ref] && this.$refs[_ref].files[0]) {
                this.imgFileToUpload = this.$refs[_ref].files[0]

                if(Helper.validateFileToUpload(this.imgFileToUpload, 'image') == false){
                    this.imgFileToUpload = null

                    this.isFileError = true
                }
            }
        },
        async uploadFile() {
            let formData = new FormData()
            formData.append('files', this.imgFileToUpload)
            const resFileUpload = await API.post('upload', formData)
            return resFileUpload.data[0].id
        },
        async selectInbox(inbox, i) {
            console.log('inbox : ', inbox)
			this.selectedInboxNo = i;
            this.selectedInbox = inbox;
            $("#inbox-list>li.active").removeClass("active")
            $("#inbox" + i).addClass("active")
			$("#v-editor").find("img").css("width","100%")
        },
        getDatetime(datetime) {
            return moment(datetime).format("DD MMM YYYY hh:mm A")
        },
        setHTMLContent(_val, _arg){
            if(_arg == 'liveCourseObj') {
                this[_arg].embed_html = _val
            } else {
                this[_arg].html_content = _val
            }
        },
    },
    async mounted(){
        await this.fetchData()
    }
}
</script>

<style lang="scss" scoped>
@import "./../../assets/scss/color.scss";
@import "./../../assets/scss/main.scss";

#page-halaman {
    .featured-img {
        img {
            width: 100%;
        }
    }

    .text-ecllips {
        overflow: hidden;
        text-overflow: ellipsis;
        -webkit-line-clamp: 12;
        -webkit-box-orient: vertical;
        display: -webkit-box;
    }

    .container-box {
        padding: 10px;
        border-radius: 5px;
        background-color: $white;

        .nav-tabs {
            .nav-item {
                .nav-link {
                    color: $gray-reqular !important;

                    &.active {
                        border: unset;
                        border-bottom: 1px solid $green;
                        color: $green !important;
                    }
                }
            }
        }
    }
}  

.custom-list {
  border: 0;
  border-bottom: 1px solid #b5b5b5;
  &.active {
    background-color: #8bc53f !important;
  }
  .inbox-date {
      font-size: 10px;
      color: #6e6e6e;
  }
}
</style>